<template>
  <div class="big_div">
    <!-- 头 -->
    <div class="header">
      <div class="bottom-div">
        <!-- 左侧个人信息 -->
        <div class="bottom-left-div">
          <!-- 头像 -->
          <div class="head-div">
            <img v-if="isLogin && userInfo.comId && userInfo.comHeadImg" :src="userInfo.comHeadImg" alt="avatar" />
            <img v-else-if="!userInfo.comId && userInfo.headImg" :src="userInfo.headImg" alt="avatar" />
            <img v-else-if="!userInfo.comHeadImg" src="@/assets/imgs/default_img.svg" alt="avatar" />
            <div :class="userInfo.comId && userInfo.comCreditStatus === 1
                ? 'creditSign'
                : userInfo.comCreditStatus === 0
                  ? 'centreCreditSign'
                  : ''
              "></div>
          </div>
          <!-- 右侧信息 -->
          <div class="right-name-div">
            <div class="identity-div">
              {{ comName ? comName : '个人身份' }}
            </div>
            <div class="switch-div" @click="handleShowCompanyUpdate">
              <img src="../../assets/imgs/homePage/switchImg.svg" alt="" srcset="" />
              <div class="switch-font-div">切换</div>
            </div>
          </div>
        </div>
        <!-- 右侧订购 -->
        <div class="bottom-right-div">
          <div class="go-order-div" @click="goAccount">
            去订购
            <img src="../../assets/imgs/homePage/triangle.svg" alt="" srcset="" />
          </div>
          <div class="num-div">
            可用份数 <span class="span">{{ userInfo.comId ? userInfo.leftUmber : num }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <Content :templateFileList="templateFileList" :applyFileList="applyFileList" :dealObject="dealObject" />
    </div>
    <!-- 尾部 -->
    <div class="zhan"></div>

    <ZddCompanyUpdate v-if="isLogin" v-model="showZddCompanyUpdate" :cur-com-id="isLogin ? userInfo.comId : null"
      @updateSuccess="getList" />
    <van-popup v-model="dialogShow">
      <img src="@/assets/imgs/banner_dialog.png" class="bannerDialogImg" />
    </van-popup>
  </div>
</template>

<script>
import homePageApi from '@/api/homePage'
import ZddCompanyUpdate from '@/components/Zdd/ZddCompanyUpdate.vue'
import accountApi from '@/api/account'
import { mapGetters } from 'vuex'
import Content from './components/Content.vue'

export default {
  components: {
    Content,
    ZddCompanyUpdate,
  },
  data() {
    return {
      showZddCompanyUpdate: false,
      templateFileList: [],
      applyFileList: [],
      dealObject: {},
      dialogShow: false,
      num:0
    }
  },
  computed: {
    ...mapGetters({
      companyList: 'companyList',
    }),
    userInfo() {
      return this.$store.getters.getUserInfo
    },
    isLogin() {
      return !!this.$store.getters.getUserInfo.userId
    },
    comName() {
      return this.$store.getters.getUserInfo.comName
    },
    activationStatus() {
      return this.isLogin && !this.userInfo.telephone && !this.userInfo.email
    },
  },
  mounted() {
    this.getHomePageList()
    this.getComingEnd()
    this.getPersonAccountInfo()
  },
  methods: {
    // 可用份数
    getPersonAccountInfo() {
      accountApi
        .getPersonAccountInfo({
          identity: this.userInfo.comId ? 1 : 0,
        })
        .then(res => {
          this.num = res.leftUmber
        })
    },
    // 去认证
    goAccount() {
      // 登录状态且账号未激活，跳转至账号激活页面
      if (this.activationStatus) {
        this.$router.push({
          name: 'qywxAccountActivation',
        })
        return
      }
      this.$router.push({
        path: '/account',
      })
    },
    getList() {
      this.getHomePageList()
      this.getComingEnd()
      this.getPersonAccountInfo()
    },
    async getComingEnd() {
      try {
        let data = await homePageApi.getComingEnd({
          endNum: null,
          allNum: null,
          dealMyNum: null,
          dealHimNum: null,
          draftNum: null,
          finishNum: null,
          meNum: null,
        })
        this.dealObject = data
      } catch (res) {
        console.log('res: ', res)
      }
    },
    async getHomePageList() {
      try {
        let data = await homePageApi.getHomePageList()
        console.log('data: ', data)
        this.applyFileList = data.applyFileList
        this.templateFileList = data.templateFileList
        this.$emit('getHomePageList')
      } catch (res) {
        console.log('res: ', res)
      }
    },
    handleShowCompanyUpdate() {
      this.showZddCompanyUpdate = true
    },
  },
}
</script>

<style lang="less" scoped>
.big_div {
  background: #f4f5f5;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 111px;
    display: flex;
    justify-content: center;
    background-image: url('../../assets/imgs/homePage/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    align-items: center;

    .bottom-div {
      width: 344px;
      height: 51px;
      display: flex;
      justify-content: space-between;

      .bottom-left-div {
        display: flex;

        .head-div {
          width: 51px;
          height: 51px;
          border-radius: 50%;
          position: relative;
          // background: linear-gradient(170deg, #f4d579 7%, #efb444 95%);
          border-radius: 50%;

          img {
            width: 51px;
            height: 51px;
            border-radius: 50%;
            border: 1.76px solid #ffffff;
          }

          .creditSign {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              width: 15px;
              height: 15px;
              background: linear-gradient(170deg, #f4d579 7%, #efb444 95%);
              border-radius: 50%;
              bottom: 0;
              left: 34px;
              background-image: url('../../assets/imgs/credit_icon.png');
              background-repeat: no-repeat;
              background-size: 15px 15px;
            }
          }

          // .centreCreditSign {
          //   position: relative;
          //   &::after {
          //     position: absolute;
          //     left: 34px;
          //     bottom: 1px;
          //     content: '未认证';
          //     // width: 100%;
          //     // height: 17px;
          //     font-size: 10px;
          //     color: #ccc;
          //     // background-image: url('../../assets/imgs/credit_icon.png');
          //     // background-size: 15px 15px;
          //   }
          //   // &::before {
          //   //   position: absolute;
          //   //   left: 18px;
          //   //   bottom: 0;
          //   //   content: '认证中';
          //   //   color: #fff;
          //   //   font-size: 10px;
          //   //   width: 57px;
          //   //   height: 17px;
          //   //   line-height: 17px;
          //   //   text-align: center;
          //   //   padding-left: 4px;
          //   //   box-sizing: border-box;
          //   //   background: #999999;
          //   //   border-radius: 0px 1px 1px 0px;
          //   // }
          // }
        }

        .right-name-div {
          // width: 60px;
          height: 100%;
          margin-left: 15px;
          display: flex;
          // flex-wrap: wrap;
          flex-direction: column;

          .identity-div {
            // width: 100%;
            max-width: 135px;
            height: 24px;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: justify;
            color: #ffffff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 24px;
          }

          .switch-div {
            width: 54px;
            height: 17px;
            background: #ffffff;
            border-radius: 2px;
            margin-top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.16);

            .switch-font-div {
              // width: 22px;
              height: 12px;
              font-size: 11px;
              font-weight: 400;
              text-align: justify;
              color: #ffffff;
              line-height: 12px;
              margin-left: 4.32px;
            }
          }
        }
      }

      .bottom-right-div {
        // width: 69px;
        height: 100%;

        .go-order-div {
          width: 69px;
          height: 27px;
          background: #ffffff;
          border-radius: 2px;
          font-size: 13px;
          font-weight: 500;
          text-align: center;
          color: #1676ff;
          line-height: 27px;
        }

        .num-div {
          // width: 66px;
          height: 20px;
          font-size: 11px;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 20px;
          margin-top: 1.5px;
          display: flex;

          .span {
            width: 25px;
            display: block;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .content {
    overflow: auto;
    padding-bottom: 21px;
    height: 100vh;
  }

  .zhan {
    height: calc(54px + constant(safe-area-inset-bottom));
    height: calc(54px + env(safe-area-inset-bottom));
  }
}
</style>
