import { get, post } from './axios'

export default {
  // 首页处理状态
  getComingEnd(param) {
    return get('/core/contractBusiness/getComingEnd', param)
  },
  getHomePageList() {
    return post('/core/templateBusiness/getHomePageList')
  },
}
