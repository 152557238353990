<template>
  <div>
    <!-- 发起路口 -->
    <div class="initiate-div">
      <div class="initiate-bottom-div">
        <div @click="handleToLaunch">
          <img src="../../../assets/imgs/homePage/fastinitiate.png" alt="" />
          <div>快速发起</div>
        </div>
        <input type="file" class="inputFile" />
        <div @click="publishClick">
          <img src="../../../assets/imgs/homePage/template.png" alt="" />
          <div>模板发起</div>
        </div>
        <div @click="applicationClick">
          <img src="../../../assets/imgs/homePage/application.png" alt="" />
          <div>应用发起</div>
        </div>
        <div>
          <img src="../../../assets/imgs/homePage/expectation.png" alt="" />
          <div style="color: #999999;">敬请期待</div>
        </div>
      </div>
    </div>
    <!-- 完成状态 -->
    <div class="state-div">
      <div class="state-content-div">
        <div class="small-content-div" @click="jumpList('dealMyNum')">
          <div class="small-span-div">
            {{ dealObject.dealMyNum ? dealObject.dealMyNum : 0 }}
          </div>
          <div class="wid-div">待我处理</div>
        </div>
        <div class="small-content-div sssss" @click="jumpList('dealHimNum')">
          <div>{{ dealObject.dealHimNum ? dealObject.dealHimNum : 0 }}</div>
          <div class="wid-div">待他人处理</div>
        </div>
        <div class="small-content-div ssss" @click="jumpList('endNum')">
          <div>{{ dealObject.endNum ? dealObject.endNum : 0 }}</div>
          <div class="wid-div">即将截止</div>
        </div>
        <div class="small-content-div sss" @click="jumpList('meNum')">
          <div>{{ dealObject.meNum ? dealObject.meNum : 0 }}</div>
          <div class="wid-div">我发起的</div>
        </div>
        <div class="small-content-div" @click="jumpList('draftNum')">
          <div>{{ dealObject.draftNum ? dealObject.draftNum : 0 }}</div>
          <div class="wid-div">草稿</div>
        </div>
        <div class="small-content-div sssss" @click="jumpList('finishNum')">
          <div>{{ dealObject.finishNum ? dealObject.finishNum : 0 }}</div>
          <div class="wid-div">已完成</div>
        </div>
      </div>
    </div>
    <!-- 常用变量模板 -->
    <div :class="templateFileList.length > 0
      ? 'variable-template-div'
      : 'no-variable-template-div'
      ">
      <div class="title-template-div">
        常用<span style="color: #0075FF;">变量</span>模板
      </div>
      <div v-if="templateFileList.length > 0">
        <div class="template-content-div">
          <div class="small-content-div" v-for="item in templateFileListAll" :key="item.id" @click="jumpVariable(item)">
            <img v-if="item.logo" :src="item.logo" alt="" />
            <img v-else src="../../../assets/imgs/homePage/peopleOutside.svg" alt="" srcset="" />
            <div>
              {{ item.name }}
            </div>
          </div>
          <div v-if="templateFileList.length <= 0" class="small-content-div">
            <img src="../../../assets/imgs/homePage/increase.svg" alt="" />
            <div class="wid-div">添加常用</div>
          </div>
        </div>
        <!-- 分界线 -->
        <div class="divide-div"></div>
        <!-- 展开 -->
        <div class="expand-div" @click="expandClick('showTemplateAll')">
          <div class="expand">{{ word }}</div>
          <img src="../../../assets/imgs/homePage/arrowSvg.svg" alt="" />
        </div>
      </div>
      <div v-else class="notHave-content-div">
        <div class="notHave-bottom-content-div">
          <div class="notHave-left-content-div">
            <div class="notHave-left-top-content-div">
              制定标准合同模板 内部审批更高效
            </div>
            <div class="notHave-left-last-content-div">
              合同仅限授权员工发起 内容安全有保障
            </div>
          </div>
          <!-- <div class="notHave-right-content-div"> -->
          <van-button @click="addClick">
            <img src="../../../assets/imgs/homePage/addIcon.svg" alt="" srcset="" />
            去添加</van-button>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <!-- 常用变量模板 含审批流程-->
    <div :class="applyFileList.length > 0
      ? 'variable-template-div'
      : 'no-variable-template-div'
      ">
      <div class="title-template-div">
        常用<span style="color: #0554C5;">应用</span>模板（含审批流程）
      </div>
      <div v-if="applyFileList.length > 0">
        <div class="template-content-div">
          <div class="small-content-div" v-for="item in applyFileListAll" :key="item.id" @click="jumpApplication(item)">
            <img v-if="item.logo" :src="item.logo" alt="" />
            <img v-else src="../../../assets/imgs/homePage/peopleOutside1.svg" alt="" srcset="" />
            <div>
              {{ item.name }}
            </div>
          </div>
          <div v-if="applyFileList.length <= 0" class="small-content-div">
            <img src="../../../assets/imgs/homePage/increase.svg" alt="" />
            <div class="wid-div">添加常用</div>
          </div>
        </div>
        <!-- 分界线 -->
        <div class="divide-div"></div>
        <!-- 展开 -->
        <div class="expand-div" @click="expandClick('showApplyAll')">
          <div class="expand">{{ wordApply }}</div>
          <img src="../../../assets/imgs/homePage/arrowSvg.svg" alt="" />
        </div>
      </div>
      <div v-else class="notHave-content-div-variableImg">
        <div class="notHave-bottom-content-div">
          <div class="notHave-left-content-div">
            <div class="notHave-left-top-content-div">
              配置内部审批流程+外部签署方
            </div>
            <div class="notHave-left-last-content-div-width">
              管控企业内部审批流程 合同规范发起有保障
            </div>
          </div>
          <van-button @click="addClick">
            <img src="../../../assets/imgs/homePage/addIcon.svg" alt="" srcset="" />
            去添加</van-button>
        </div>
      </div>
    </div>
    <!-- 示例 -->
    <!-- <div class="arrange-div">
      <div class="arrange-bottom-div">
        <div class="arrange-left-div">
          <div class="arrange-left-top-div">
            新用户必看：如何发起一份电子合同
          </div>
          <div class="arrange-left-bottom-div">
            2022-04-29
          </div>
        </div>
        <div class="arrange-right-div"></div>
      </div>
    </div> -->
    <AsyncContractList :show.sync="contractListShow" @confirm="contractConfirm" :multiple="false" :type="type"
      :title="title" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog } from 'vant'
import { hasCookies } from '@/utils/cookies'
import { isWxMiniProgram } from '@/utils/ua'
import templateFileApis from '@/api/core/templateFile'
import { navigateToMiniLaunch, navigateToMiniLogin } from '@/utils/mini'
import { createSimpleLaunch } from '@/model/launch'

const AsyncContractList = Vue.component('AsyncContractList', () =>
  import('../../publish/components/ContractList.vue')
)

export default {
  name: 'Content',
  components: {
    AsyncContractList,
  },
  props: {
    templateFileList: {
      type: Array,
      default: () => [],
    },
    applyFileList: {
      type: Array,
      default: () => [],
    },
    dealObject: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      showTemplateAll: false,
      showApplyAll: false,
      contractListShow: false,
      type: 2,
      title: '',
      copyText: 'https://www.ecloudsign.com/',
      contracList: [],
    }
  },
  computed: {
    templateFileListAll() {
      if (!this.showTemplateAll) {
        let showList = []
        if (this.templateFileList.length > 10) {
          showList = this.templateFileList.slice(0, 10)
        } else {
          showList = this.templateFileList
        }
        return showList
      }
      return this.templateFileList
    },
    applyFileListAll() {
      if (!this.showApplyAll) {
        let showList = []
        if (this.applyFileList.length > 10) {
          showList = this.applyFileList.slice(0, 10)
        } else {
          showList = this.applyFileList
        }
        return showList
      }
      return this.applyFileList
    },
    word() {
      if (!this.showTemplateAll) {
        return '展开'
      }
      return '收起'
    },
    wordApply() {
      if (!this.showApplyAll) {
        return '展开'
      }
      return '收起'
    },
    isLogin() {
      return !!this.$store.getters.getUserInfo.userId
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
    // 账号激活状态 需要激活 true 无需激活 false
    activationStatus() {
      return this.isLogin && !this.userInfo.telephone && !this.userInfo.email
    },
  },
  mounted() { },
  methods: {
    // 展开
    expandClick(val) {
      if (val === 'showTemplateAll') {
        this.showTemplateAll = !this.showTemplateAll
      } else {
        this.showApplyAll = !this.showApplyAll
      }
    },
    handleToLogin() {
      if (isWxMiniProgram()) {
        console.log('HomePage')
        navigateToMiniLogin(true, '/HomePage')
      } else {
        this.$router.push({
          name: 'Login',
        })
      }
    },
    // 快速发起
    async handleToLaunch() {
      console.log('快速发起')
      try {
        if (this.activationStatus) {
          // 登录状态且账号未激活，跳转至账号激活页面
          this.$router.push({
            name: 'qywxAccountActivation',
          })
          return
        }
        if (hasCookies()) {
          if (isWxMiniProgram()) {
            navigateToMiniLaunch()
          } else {
            await createSimpleLaunch()
          }
        } else {
          this.handleToLogin()
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 模板发起
    async publishClick() {
      try {
        const res = await templateFileApis.getHomePageClassifyInfoList({
          pageNum: 1,
          pageSize: 1,
          searchType: 2,
        })
        const { rowsList } = res
        this.type = 2
        if (rowsList.length === 0) {
          this.prompt()
          return
        }
        this.title = '模板列表'
        this.contractListShow = true
      } catch (error) {
        console.error(error)
      } finally {
        this.$toast.clear()
      }
    },
    // 应用模板发起
    async applicationClick() {
      try {
        const res = await templateFileApis.getHomePageClassifyInfoList({
          pageNum: 1,
          pageSize: 1,
          searchType: 0,
        })
        const { rowsList } = res
        this.type = 0
        if (rowsList.length === 0) {
          this.prompt()
          return
        }
        this.title = '应用列表'
        this.contractListShow = true
      } catch (error) {
        console.error(error)
      } finally {
        this.$toast.clear()
      }
    },
    /** 无合同模板/业务应用提示 */
    prompt() {
      Dialog.confirm({
        title: '提示',
        message:
          '当前账号暂无合同模板/业务应用，请电脑访问https://www.ecloudsign.com/ 创建模板/应用',
        confirmButtonText: '关闭',
        cancelButtonText: '复制链接',
        cancelButtonColor: '#1676FF',
      })
        .then(() => { })
        .catch(async () => {
          await this.$copyText(this.copyText)
          this.$toast({
            message: '复制链接成功',
          })
        })
    },
    /** 选择变量模版合同 */
    async contractConfirm(data, item) {
      if (this.type === 2) {
        this.$router.push({
          name: 'publishTemplate',
          query: {
            templateId: data,
          },
        })
      } else if (this.type === 0) {
        this.$router.push({
          name: 'publishPacking',
          query: {
            businessId: data,
          },
        })
      }
    },
    // 去添加变量/应用
    addClick() {
      Dialog.confirm({
        title: '提示',
        message:
          '当前账号暂无常用合同模板/应用，请在电脑端访问易云章官网https://www.ecloudsign.com/，在「业务」板块，设置常用',
        confirmButtonText: '关闭',
        cancelButtonText: '复制链接',
        cancelButtonColor: '#1676FF',
      })
        .then(() => { })
        .catch(async () => {
          await this.$copyText(this.copyText)
          this.$toast({
            message: '复制链接成功',
          })
        })
    },
    // 跳转变量模板发起
    jumpVariable(val) {
      this.$router.push({
        name: 'publishTemplate',
        query: {
          templateId: val.id,
        },
      })
    },
    // 跳转应用模板发起
    jumpApplication(val) {
      // 跳转到应用模板发起
      this.$router.push({
        name: 'publishPacking',
        query: {
          // 传递businessId参数
          businessId: val.id,
        },
      })
    },
    // 跳转文件列表
    jumpList(name) {
      if (name === 'dealMyNum') {
        // 待我处理
        this.$router.push({
          name: 'List',
          query: {
            fileStatus: 2,
          },
        })
      } else if (name === 'dealHimNum') {
        // 待他人处理
        this.$router.push({
          name: 'List',
          query: {
            fileStatus: 3,
          },
        })
      } else if (name === 'endNum') {
        // 即将截至
        this.$router.push({
          name: 'List',
          query: {
            fileStatus: 6,
          },
        })
      } else if (name === 'meNum') {
        // 我发起的
        this.$router.push({
          name: 'List',
          query: {
            fileStatus: 0,
          },
        })
      } else if (name === 'draftNum') {
        // 草稿
        this.$router.push({
          name: 'List',
          query: {
            fileStatus: 1,
          },
        })
      } else if (name === 'finishNum') {
        // 已完成
        this.$router.push({
          name: 'List',
          query: {
            fileStatus: 4,
          },
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.initiate-div {
  width: 100%;
  height: 93px;
  background: #ffffff;
  padding: 18px 20px 9px 24px;

  .initiate-bottom-div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inputFile {
      display: none;
    }

    div {
      width: 55px;
      height: 64px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 13px;

      div {
        width: 55px;
        height: 24px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
      }

      img {
        width: 36px;
        height: 36px;
      }
    }
  }
}

.state-div {
  width: 100%;
  height: 138px;
  background: #ffffff;
  margin-top: 8px;
  display: flex;
  justify-content: space-around;

  .state-content-div {
    margin-left: 19px;
    margin-top: 13.27px;
    display: flex;
    flex-wrap: wrap;

    .small-content-div {
      width: 70px;
      text-align: justify;
      flex-wrap: wrap;
      margin-right: 19px;
      margin-bottom: 17.25px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      div {
        // width: 19px;
        height: 24px;
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        color: #333333;
        line-height: 24px;
      }

      .wid-div {
        width: 70px;
        height: 18px;
        opacity: 0.7;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        color: #333333;
        line-height: 18px;
        margin-top: 2.5px;
      }
    }

    .sssss {
      &::before {
        width: 1px;
        height: 13px;
        background: rgba(51, 51, 51, 0.14);
        position: absolute;
        top: 13.5px;
        right: 83px;
        content: '';
      }
    }

    .ssss {
      &::before {
        width: 1px;
        height: 13px;
        background: rgba(51, 51, 51, 0.14);
        position: absolute;
        top: 13.5px;
        right: 76px;
        content: '';
      }
    }

    .sss {
      &::before {
        width: 1px;
        height: 13px;
        background: rgba(51, 51, 51, 0.14);
        position: absolute;
        top: 13.5px;
        right: 79px;
        content: '';
      }
    }
  }
}

.no-variable-template-div {
  width: 100%;
  // height: 132px;
  background: #fff;
  margin-top: 8px;
  padding-bottom: 12px;

  .title-template-div {
    height: 46px;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    padding: 14.07px 0 0 24px;
  }

  .notHave-content-div {
    width: 327px;
    // height: 72px;
    // background: #262626;
    margin: 0 auto;
    background-image: url('../../../assets/imgs/homePage/templateImage.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 12px 12px 12px;

    .notHave-bottom-content-div {
      width: 303px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .notHave-left-content-div {
        height: 45px;

        .notHave-left-top-content-div {
          height: 20px;
          font-size: 13px;
          font-weight: 500;
          color: #262626;
          line-height: 20px;
        }

        .notHave-left-last-content-div {
          // width: 191px;
          height: 24px;
          opacity: 0.7;
          font-size: 11px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }

        .notHave-left-last-content-div-width {
          width: 217px;
          height: 24px;
          opacity: 0.7;
          font-size: 11px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }

      /deep/ .van-button {
        width: 69px;
        height: 27px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 20px;
        padding: 0;
        border-radius: 2px;
        background: #0075ff;

        img {
          width: 10px;
          height: 10px;
          vertical-align: baseline;
        }
      }
    }
  }

  .notHave-content-div-variableImg {
    width: 327px;
    // height: 72px;
    // background: #262626;
    margin: 0 auto;
    background-image: url('../../../assets/imgs/homePage/variableImg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 12px 12px 12px;

    .notHave-bottom-content-div {
      width: 303px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .notHave-left-content-div {
        // width: 204px;
        height: 45px;

        // background: red;
        .notHave-left-top-content-div {
          // width: 204px;
          height: 20px;
          font-size: 13px;
          font-weight: 500;
          color: #262626;
          line-height: 20px;
        }

        .notHave-left-last-content-div {
          // width: 191px;
          height: 24px;
          opacity: 0.7;
          font-size: 11px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }

        .notHave-left-last-content-div-width {
          width: 217px;
          height: 24px;
          opacity: 0.7;
          font-size: 11px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }

      /deep/ .van-button {
        width: 69px;
        height: 27px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 20px;
        border-radius: 2px;
        padding: 0;
        background: #0554c5;

        img {
          width: 10px;
          height: 10px;
          vertical-align: baseline;
        }
      }
    }
  }
}

.variable-template-div {
  width: 100%;
  // height: 264px;
  background: #fff;
  margin-top: 8px;
  padding-bottom: 12px;

  .title-template-div {
    height: 46px;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    padding: 21.32px 0 0 24px;
  }

  .template-content-div {
    margin-left: 18px;
    margin-top: 13.27px;
    display: flex;
    flex-wrap: wrap;

    // justify-content: center;
    .small-content-div {
      flex-wrap: wrap;
      margin-right: 6px;
      margin-bottom: 20px;
      width: 65px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      div {
        width: 65px;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        margin-top: 7px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // padding-left: 2px;
      }

      img {
        width: 35px;
        height: 35px;
      }

      .wid-div {
        width: 52px;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        margin-top: 7px;
      }
    }
  }

  .divide-div {
    width: 327px;
    height: 1px;
    border: 0.5px solid rgba(51, 51, 51, 0.14);
    margin: 0 auto;
  }

  .expand-div {
    width: 34px;
    height: 18px;
    margin: 0 auto;
    margin-top: 11.25px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .expand {
      width: 34px;
      height: 18px;
      font-size: 13px;
      font-weight: 400;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
      line-height: 18px;
    }
  }
}

.arrange-div {
  width: 100%;
  height: 72px;
  background: #fff;
  margin-top: 8px;
  margin-bottom: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  .arrange-bottom-div {
    width: 328px;
    height: 48px;
    display: flex;
    justify-content: space-between;

    .arrange-left-div {
      width: 208px;
      height: 100%;

      // background: pink;
      .arrange-left-top-div {
        // width: 208px;
        height: 20px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        color: #262626;
        line-height: 20px;
      }

      .arrange-left-bottom-div {
        // width: 67px;
        height: 24px;
        opacity: 0.7;
        font-size: 11px;
        font-weight: 400;
        text-align: justify;
        color: #333333;
        line-height: 24px;
      }
    }

    .arrange-right-div {
      width: 79px;
      height: 48px;
      background: #d8d8d8;
      border-radius: 2px;
    }
  }
}
</style>
